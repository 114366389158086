import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import backgroundImage from "./iphone.svg";
import logo from "./Logo_White copy.png";

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  font-family: "Arial", sans-serif;
  background: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  position: fixed;
  top: -20px;
  left: 20px;
  height: 40px;
  width: auto;

  cursor: pointer;
  z-index: 10;
  transition: transform 0.3s;
  border-radius: 29px;
  padding: 30px 20px;

  margin: 0;
  background-color: rgba(0, 0, 0, 0.85);
  &:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.95);
  }
  @media (max-width: 768px) {
    top: -10px;
    height: 5vh;   // this will adjust the logo height relative to the viewport height
    padding: 15px 10px;
    border-radius: 10px;
  }
`;

const Navbar = styled.nav`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 25px;
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0.85); // Dark background
  padding: 10px 25px;
  border-radius: 15px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  z-index: 10;

  &:hover {
    background-color: rgba(0, 0, 0, 0.95); // Slightly darker on hover
    transform: scale(1.05); // Scales the entire navbar
  }
  flex-direction: column;
  @media (min-width: 768px) {
    // Adjust this value based on where you want the breakpoint
    flex-direction: row;
  }
`;
const HamburgerIcon = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 24px;
  height: 18px;
  justify-content: space-between;
  position: relative;
  div {
    height: 3px;
    background: #fff;
    transition: all 0.3s ease-in-out;
  }

  /* X transition styles */
  div:nth-of-type(1) {
    transform: ${(props) =>
      props.isOpen ? "rotate(45deg) translateY(7px)" : "none"};
  }
  div:nth-of-type(2) {
    opacity: ${(props) => (props.isOpen ? "0" : "1")};
  }
  div:nth-of-type(3) {
    transform: ${(props) =>
      props.isOpen ? "rotate(-45deg) translateY(-7px)" : "none"};
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
`;

const NavLink = styled.a`
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
  text-decoration: none;
  color: #fff; // Set to white
  &:hover {
    color: purple; // Distinct hover color
    transform: scale(1.1);
  }
`;

const ProductsOption = styled.div`
  position: relative;
  cursor: pointer;
  color: purple;

  &:hover > div {
    display: block;
  }
`;

const Dropdown = styled.div`
  display: none;
  position: absolute;

  top: 100%;
  right: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.9),
    rgba(50, 50, 50, 0.9)
  ); // Fancy gradient background
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); // Increased shadow for a more elevated feel
  z-index: 5;
  overflow: hidden; // Ensure the rounded corners
`;

const DropdownOption = styled.a`
  display: block;

  padding: 8px 12px; // Reduced padding for smaller options
  color: #fff;
  font-size: 0.9em; // Reduced font size for a more compact look
  text-decoration: none;

  &:hover {
    background-color: purple;
    color: #000;
  }
`;

const MainDiv = styled.div`
  /* background: rgba(255, 255, 255, 0.6); // Semi-transparent card
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); // Increased shadow for a more elevated feel */
  padding: 15px;
  width: 90%;
  height: 60%;
  display: flex;
  margin-top: 50px;

  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    
    gap: 10px;
    margin-top:70px;
  }
  @media (max-width: 768px) {
    padding: 10px;
    margin-top: 50px;
   
  }
  @media (max-width: 628px) {

    flex-direction: column;
  }

  /* &:hover {
    background: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
    transform: scale(1.05); // Scales the entire navbar
  } */
`;
const InsideDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
  @media (max-width: 1000px) {
    gap: 10px;
  }

`;
const Title = styled.h2`
  font-weight: bold;
  color: black;
  font-size: 30px;
  border-radius: 5px;
  background: rgba(128, 128, 128, 0.7);
  padding: 7px;
  &:hover {
    background: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
    transform: scale(1.05); // Scales the entire navbar
    padding: 15px;
  }
`;
const Text1 = styled.span`
  font-size: 1.5vw;
  font-weight: bold;
  display: flex;
  line-height: 1; // Adjust to desired value
  vertical-align: middle; // Adjusts the vertical position within line
  @media (max-width: 1000px) {
    font-size: 2.5vw;
  }
  @media (max-width: 768px) {
    font-size: 3vw;
  }
  @media (max-width: 450px) {
    font-size: 4vw;
  }
`;
const TextDiv = styled.div`
  display: flex;
  width: 45%;
`;
const Text = styled.div`
   font-size: 1vw;
   @media (max-width: 1000px) {
    font-size: 1.5vw;
  }
  @media (max-width: 768px) {
    font-size: 1.8vw;
  }
  @media (max-width: 450px) {
    font-size: 2vw;
  }
`;

const SmallDiv = styled.div`
  display: flex;
  width: 70%;
  background: rgba(255, 255, 255, 0.6); // Semi-transparent card
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); // Increased shadow for a more elevated feel
  padding: 15px;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
    transform: scale(1.05); // Scales the entire navbar
  }
  font-size: 1vw;
   @media (max-width: 1000px) {
    font-size: 1.5vw;
    padding: 12px;
  }
  @media (max-width: 568px) {
    font-size: 1.8vw;
    padding: 9px;
  }
  @media (max-width: 450px) {
    font-size: 2vw;
    padding: 6px;
  }
`;
const SmallDiv2 = styled.div`
  display: flex;
  width: 60%;
  background: rgba(255, 255, 255, 0.6); // Semi-transparent card
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); // Increased shadow for a more elevated feel
  padding: 15px;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
    transform: scale(1.05); // Scales the entire navbar
  }
  font-size: 1vw;
   @media (max-width: 1000px) {
    font-size: 1.5vw;
    padding: 12px;
  }
  @media (max-width: 568px) {
    font-size: 1.8vw;
    padding: 9px;
  }
  @media (max-width: 450px) {
    font-size: 2vw;
    padding: 6px;
  }
`;
const SmallDiv1 = styled.div`
  display: flex;
  z-index: 2;
  width: 95%;
  background: rgba(255, 255, 255, 0.6); // Semi-transparent card
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); // Increased shadow for a more elevated feel
  padding: 15px;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
    transform: scale(1.05); // Scales the entire navbar
  }
  font-size: 1vw;
   @media (max-width: 1000px) {
    font-size: 1.5vw;
    padding: 12px;
  }
  @media (max-width: 568px) {
    font-size: 1.8vw;
    padding: 9px;
  }
  @media (max-width: 450px) {
    font-size: 2vw;
    padding: 6px;
  }
`;

const SeparatingDiv1 = styled.div`
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(82, 8, 56, 1) 0%,
    rgba(141, 21, 145, 1) 100%
  );

  height: 30px;
  position: absolute;
  z-index: 1;
  top: ${(props) => props.windowHeight - 15}px;
`;
const SeparatingDiv2 = styled.div`
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(74, 10, 62, 1) 0%,
    rgba(82, 8, 56, 1) 100%
  );

  height: 30px;
  position: absolute;
  z-index: 1;
  top: ${(props) => props.windowHeight - 15}px;
`;

const HomePage = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isMobileNavOpen) {
        const navbar = document.querySelector("nav");
        if (!navbar.contains(event.target)) {
          setMobileNavOpen(false);
        }
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isMobileNavOpen]);

  useEffect(() => {
    // 2. Update windowSize state whenever the window is resized
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const scrollDownTeamPage = () => {
    window.scrollTo({
      top: window.innerHeight * 2,
      behavior: "smooth",
    });
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <PageContainer>
      <Logo src={logo} alt="Company Logo" onClick={scrollToTop} />
      <Navbar>
        <HamburgerIcon
          isOpen={isMobileNavOpen}
          onClick={() => setMobileNavOpen(!isMobileNavOpen)}
        >
          <div></div>
          <div></div>
          <div></div>
        </HamburgerIcon>
        <MobileNav isOpen={isMobileNavOpen}>
          <NavLink onClick={scrollToTop}>Main</NavLink>
          <ProductsOption onClick={scrollDown}>
            Our Apps
            <Dropdown>
              <DropdownOption onClick={scrollDown} href="#product1">
                iOS/Android Apps
              </DropdownOption>
              <DropdownOption onClick={scrollDown} href="#product2">
                Restaurant Platform
              </DropdownOption>
              <DropdownOption onClick={scrollDown} href="#product3">
                Web App
              </DropdownOption>
            </Dropdown>
          </ProductsOption>
          <NavLink onClick={scrollDownTeamPage}>About the Team</NavLink>
        </MobileNav>
      </Navbar>
      <MainDiv>
        <InsideDiv>
          <SmallDiv2>
            <Text1>Introducing the "Presto App" </Text1>
            <br />
            In today's fast-paced, increasingly digital world, the traditional
            dining experience often feels out of place and tedious. Lengthy
            waits after placing an order, the challenge of finding a table
            during peak hours, and the age-old hassle of splitting bills with
            friends – these are just a few of the pain points modern diners
            face. Enter the Dining All-in-One App, your comprehensive solution
            to transform the global food and dining industry.
            <br />
            <br />
          </SmallDiv2>
          <SmallDiv>
            <Text1>The Problem:</Text1>
            <br />
            1.Lengthy waits at restaurants can dampen the dining experience,
            especially when hunger pangs strike hard. <br />
            2.Securing a table during peak dining hours can be a daunting task.{" "}
            <br />
            3. In thriving dining hubs like Tbilisi, the digital potential
            remains largely untapped, even with a massive quarterly turnover.{" "}
            <br />
            4. Smaller eateries find it challenging to effectively present
            themselves and attract customers.
            <br />
            <br />
            <Text1>Our solution? The Presto App.</Text1>
            <br />
          </SmallDiv>
        </InsideDiv>
        <InsideDiv>
          <SmallDiv1>
            <Text1>Our Solution: </Text1>
            <br />
            Centralizing Restaurants: Our platform offers users access to a
            plethora of diverse food outlets. Whether you're looking for a cozy
            diner or a bustling bistro, we've got you covered.
            <br />
            <br />
            Instant Orders and Zero Wait Times: Say goodbye to the era of
            waiting! With our app, users can reserve tables, pre-order food, and
            step into the restaurant to instantly enjoy their dish upon arrival.
            <br />
            <br />
            Simplified Reservations: Peak hours in Georgia? No problem! Our app
            makes table reservations a breeze, even during the busiest times.
            <br />
            Innovative Order and Payment System: Diners can order food
            separately right from their mobile phones, assign it to a specific
            table, and split the bill with ease – eliminating inconvenience and
            awkward moments.
            <br />
            <br />
            Empowering Smaller Eateries: With our platform, even the smallest of
            restaurants can showcase their specialties and attract a wider
            audience.
            <br />
            <br />
            Seamless Operations for Restaurants: The platform facilitates
            real-time menu updates and efficient order management, ensuring
            restaurant operations run smoother than ever.
            <br />
          </SmallDiv1>

          <SmallDiv>
            <Text1>Why Now? </Text1>
            <br />

            <Text>
              In an era defined by rapid digital advancement, the global dining
              industry lags, operating predominantly offline. This scenario
              unfolds a massive market opportunity, especially when the clamor
              for digital menus, easy payments, and seamless reservations is at
              an all-time high. While app usage in various sectors is witnessing
              an explosive growth, the dining realm is still catching up.
              Georgia, particularly cities like Tbilisi, stands as a testament
              to this untapped potential with its burgeoning food sector
              boasting a quarterly turnover of ₾500 million.
            </Text>
          </SmallDiv>
        </InsideDiv>
      </MainDiv>
      <SeparatingDiv1 windowHeight={windowSize.height}></SeparatingDiv1>
      <SeparatingDiv2 windowHeight={windowSize.height * 2}></SeparatingDiv2>
    </PageContainer>
  );
};

export default HomePage;
