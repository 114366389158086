import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import HomeScreen from "./components/HomeScreen";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path = '/'>
       <Route index={true} element={<HomeScreen/>}/>
     
      
    </Route>
    

    
  )
)

function App() {
  return (
    <RouterProvider router = {router}/>
  );
}

export default App;
