import React from 'react'
import Slider from './pageComponents/Slider'
import HomePage from './pageComponents/HomePage'
import TeamPage from './pageComponents/TeamPage'
import "./HomeScreen.css"







export default function HomeScreen() {
  
  return (
      <div className="container">
        <HomePage/>
        <Slider/>
        <TeamPage/>
      </div>
      

   
  )
}
