// Slider.js
import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import YouTube from "react-youtube";
import img from "./background.jpg";

const SliderContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  
`;

const Slide = styled.div`
  position: absolute;
  
  min-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  opacity: ${(props) => (props.active ? "1" : "0")};
  transition: opacity 1s;
  background-image: url(${(props) => props.image});
`;
const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  &::before {
    content: "";
    border: solid white;
    border-width: 2px 2px 0 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(
      ${(props) => (props.left ? "-135deg" : "45deg")}
    ); // adjusted the rotation for left and right arrows
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    margin-top: 40px;
  }
`;
const VideoWrapper = styled.div`
  width: 100%; // To make video fit inside its container
  cursor: pointer;
  display: flex;
  justify-content: center;
  z-index: 5;
 
`;

const RightDiv = styled.div`
  width: 95%; // Full width initially
  max-width: 500px; // Define a max width
  height: auto;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px;
  
  z-index: 3;
  font-size: clamp(1vw, 1.4vw, 1.5vw);
  text-overflow: ellipsis;
  overflow-y: auto;

  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    transition: 0.6s;
    transform: scale(1.03);
  }
  @media (max-width: 900px) {
    font-size: 15px;
    
  }
  @media (max-width: 450px) {
    font-size: 13px;
    
  }
`;

const MiddleDiv = styled.div`
  width: 100%; // Avoid edge-to-edge width
  height: auto; // Let the content define the height
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
const InsideDiv = styled.div`
  width: 100%;
  height: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  box-sizing: border-box;
  @media (min-width: 1000px) {
    // Switch to horizontal layout on larger screens
    flex-direction: row;
  }
`;

const PrevButton = styled(Button)`
  left: 10px;
`;

const NextButton = styled(Button)`
  right: 10px;
`;

const Title = styled.div`
  color: white;
  font-size: 50px;
  font-weight: " 1200";
  @media (max-width: 768px) {
    font-size: 1.5rem; // Adjusting for mobile screens
  }
`;

const Slider = () => {
  const slides = [
    {
      image: img,
      text: `
      
      Our  iOS and Android apps revolutionize your dining experience. Explore a wide range of dining options, from cozy bistros to elegant eateries, effortlessly at your fingertips.
      
      Bid farewell to long lines and peak-hour table scarcity. Our app simplifies table reservations, ensuring you're seated promptly. Ordering is a breeze, allowing you to place food orders directly through your phone, and secure, in-app payments eliminate the need for cash.
      
      Real-time updates keep you informed about your order's status and estimated arrival, ensuring your meal is ready when you are. Dining with friends? Splitting bills becomes a breeze with our app's feature, freeing you from tedious calculations.
      
      Access user reviews to make informed choices and provide instant feedback after your meal. Plus, unlock exclusive offers and promotions, enhancing your dining experience.
      
      Our app is designed for efficiency, convenience, and a stress-free dining adventure, ensuring each meal is a delightful experience.
      `,
      Title: "iOS and Android Apps",
      YLink: "2VGr92mIniI",
    },
    {
      image: img,
      text: `Our restaurant platform is a versatile tool for restaurant owners. It enables effortless menu updates, efficient order management, and improved customer communication.
      We're not stopping there. We're developing a dedicated companion app, integrated into our Presto family, to provide restaurant owners with powerful tools. This app will help them manage sales, access in-depth analytics, track inventory, and streamline overall restaurant operations. It's all about empowering restaurant owners to run their businesses efficiently and enhance the dining experience for their customers.`,
      Title: "Restaurant Platform",
      YLink: "TtWg3PxjmUo",
    },
    {
      image: img,
      text: `Activated through restaurant-specific QR codes, our web app revolutionizes dining. Users enjoy effortless menu browsing, convenient ordering, real-time updates on orders, and secure in-app payments. It's perfect for group outings with bill-splitting features. This app is designed to enhance the dining experience exclusively at the chosen restaurant, making every meal enjoyable and convenient.`,
      Title: "Web App",
      YLink: "fISW8wFBAKs",
    },
  ];

  const [videoDimensions, setVideoDimensions] = useState({
    width: window.innerWidth <= 768 ? 354 : 640,
    height: window.innerWidth <= 768 ? 200 : 390,
  });

  const updateDimensions = () => {
    setVideoDimensions({
      width: window.innerWidth <= 768 ? 354 : 640,
      height: window.innerWidth <= 768 ? 200 : 390,
    });
  };
  const updateDimensionsRef = useRef(updateDimensions);

  useEffect(() => {
    window.addEventListener("resize", updateDimensionsRef.current);

    // Cleanup the listener on unmount
    return () => {
      window.removeEventListener("resize", updateDimensionsRef.current);
    };
  }, []);

  const videoOptions = {
    height: videoDimensions.height.toString(),
    width: videoDimensions.width.toString(),
    playerVars: {
      autoplay: 0,
      controls: 1,
      rel: 0,
      fs: 1,
      modestbranding: 1,
      showinfo: 0,
    },
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <SliderContainer>
      {slides.map((slide, index) => (
        <Slide key={index} image={slide.image} active={index === currentSlide}>
          <MiddleDiv>
            <Title>{slide.Title}</Title>
            <InsideDiv>
              <VideoWrapper>
                <YouTube videoId={slide.YLink} opts={videoOptions} />
              </VideoWrapper>
              <RightDiv>{slide.text}</RightDiv>
            </InsideDiv>
          </MiddleDiv>
        </Slide>
      ))}
      <PrevButton left onClick={prevSlide} />
      <NextButton onClick={nextSlide} />
    </SliderContainer>
  );
};

export default Slider;
