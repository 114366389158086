import React from "react";
import styled from "@emotion/styled";
import background from "./teamBack.jpg"; // Ensure the correct image path
import img1 from "./pic4.png";
import img2 from "./pic3.png";

const PageContainer = styled.div`
 
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row; // Changed from column to row
  @media (max-width: 1000px) {
    flex-direction: column;
   
  }
`;

const TeammateCard = styled.div`
  background: rgba(255, 255, 255, 0.6); // Semi-transparent card
  border-radius: 15px;
  padding: 20px;
  margin-top: 90px;
  display: flex;
  gap: 20px;
  align-items: center;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
  width: 480px;
  height: auto;
  transition: opacity 0.3s ease; // Transition for opacity

  &:hover {
    background: rgba(255, 255, 255, 0.9);
    transition: 0.5s;
    transform: scale(1.05);
  }
  @media (max-width: 900px) {
    width: 70%;
    margin-top: 20px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }

  @media (max-width: 768px) {
    width: 90%;
    padding: 10px;  // Further reduce padding for mobile
  }

  @media (max-width: 480px) {
    padding: 8px; // Even smaller padding for very small screens
  }
`;

const TeammateImage = styled.img`
  width: 225px; // Adjusted width for a more rectangular look
  height: 150px;
  border-radius: 10px; // Softly rounded edges for the rectangle
  margin-right: 20px;

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
  @media (max-width: 768px) {
    width: 180px;  // Reduce size for mobile view
    height: 120px;
  }
`;

const TeammateInfo = styled.div`
  flex: 1;
  font-size: 15px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 16px;  // Increase font size for better legibility on mobile
  }
`;

const TeammateName = styled.div`
  font-weight: bold;
  color: black;
  font-size: 19px;
  margin-bottom: 10px;
  
  @media (max-width: 768px) {
    font-size: 21px;  // Increase name size for mobile
  }
`;

const TeammateDetails = styled.div`
  color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  @media (min-width: 300px) {
    font-size: 13px;  // Increase name size for mobile
  }
  @media (min-width: 700px) {
    font-size: 15px;  // Increase name size for mobile
  }
  @media (min-width: 1000px) {
    font-size: 19px;  // Increase name size for mobile
  }
  
`;

const TeamPage = () => {
  return (
    <PageContainer >
      <TeammateCard>
        <TeammateImage src={img1} alt="Teammate 1" />
        <TeammateInfo>
          <TeammateName>Konstantin Bakhutashvili</TeammateName>
          <TeammateDetails>
            {" "}
            Front-end Developer specializing in mobile (customer interface), web
            (customer interface), and restaurant administration (server-side)
            applications. A deep-seated foundation of 4 years in JavaScript and
            3 years mastering React JS and React Native. Leads business-side
            development with a focus on creating innovative solutions. Student
            at the Free University of Tbilisi.{" "}
          </TeammateDetails>
        </TeammateInfo>
      </TeammateCard>
      <TeammateCard>
        <TeammateImage src={img2} alt="Teammate 2" />
        <TeammateInfo>
          <TeammateName>Sandro Lortkipanidze</TeammateName>
          <TeammateDetails>
            Develops Backend and the desktop app, streamlining order processes
            and product management. Winner of Millennium Innovation’s and Shota
            Rustaveli Foundation contests. Over 4 years in C# and 2 years in
            android development. Student at Kutaisi International University.
          </TeammateDetails>
        </TeammateInfo>
      </TeammateCard>
    </PageContainer>
  );
};

export default TeamPage;
